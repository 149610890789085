import React, { useState } from 'react';
import { SearchTool } from "@/components/index";
import { DEFAULT_SEARCH_FILTERS } from '@/lib/constants'
// const carousel = () =>
//   <div className="container w-full">

//     <Swiper
//       spaceBetween={0}
//       centeredSlides={true}
//       autoplay={{
//         "delay": 2500,
//         "disableOnInteraction": false
//       }}
//       pagination={{
//         "clickable": true
//       }}
//       slidesPerView={1}
//       effect={'fade'}
//       loop={true}
//     // navigation={true}
//     // className="mySwiper"
//     >
//       {[...new Array(10)].map(item => (
//         <SwiperSlide key={item}>
//           <img src="https://loremflickr.com/450/330" alt="" className="w-full object-cover" />
//           {/* ss */}
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   </div>
const BannerCarousel = () => {
  // 目前使用這個元件，不需要設定預設值 DEFAULT_SEARCH_FILTERS。
  const [search, setSearch] = useState({});

  return (
    <>
      <SearchTool search={search} type="button" handleClick={(filters) => setSearch(filters)}>
        <h1 className="text-white text-xl md:text-[42px] text-center v1-text-white-shadow ">找到你的理想空間</h1>
        {/* {JSON.stringify(search)} */}
      </SearchTool>
    </>
  )
}


export default BannerCarousel;
