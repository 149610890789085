import React, { useEffect, useRef, useState } from 'react';
import { string } from 'prop-types';

import styles from '@/styles/Home.module.css';
import { Layout, CtaBtn, BannerCarousel, Dropdown, Nav } from '@/components/index';
import { useRouter } from 'next/router';
import Image from 'next/image';


export const Header = ({ }) => {
  const router = useRouter();
  const isPathNotEqualHomePage = router.pathname !== '/'
  const [isSticky, setSticky] = useState(isPathNotEqualHomePage);
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const [isOpenMobileDropdown, setOpenMobileDropdown] = useState(false);
  const ref = useRef();
  const handleOpenDropDown = () => {
    // console.log(`handleOpenDropDown ${isOpenDropdown}`)
    setOpenDropdown(!isOpenDropdown)
  };

  const handleOpenMobileDropDown = () => {
    console.log(`isOpenMobileDropdown ${isOpenMobileDropdown}`)
    setOpenMobileDropdown(!isOpenMobileDropdown)
  };

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < 0);
    }
  };

  useEffect(() => {
    !isPathNotEqualHomePage && window.addEventListener('scroll', handleScroll);
    return () => {
      !isPathNotEqualHomePage && window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  const MomerizedBannerCarousel = React.memo(BannerCarousel)

  return (
    <section className={`${styles['sticky-wrapper']} overflow-hidden z-30 absolute ${router.pathname === '/' ? '' : ''} ${router.pathname === '/' ? '' : styles.stickyMargin}`} ref={ref}>
      <Nav isSticky={isSticky} isOpenMobileDropdown={isOpenMobileDropdown} handleOpenMobileDropDown={handleOpenMobileDropDown} handleOpenDropDown={handleOpenDropDown} />
      {router.pathname === '/' &&
        <div className={styles['header']}>
          <div className="relative w-full h-full">
            {/* <div className="h-full">
              <Image width="375" height="555" layout="responsive" objectFit="cover" src={`/banner/Banner_photo_1@2x.png`} alt="" />
            </div> */}
            {/* <div className={`${styles['header-mask']} hidden lg:block lg:relative w-full overflow-hidden `}>
              <Image width="375" height="555" layout="responsive" objectFit="cover" src={`/home/Background_01.svg`} alt="" />
            </div> */}
            <div className={`${styles['header-mask']} hidden lg:block lg:relative w-full overflow-hidden `}>
              <img className="w-full object-cover absolute" src="/home/Background_01.svg" alt="" />
            </div>
            <div className={`${styles['header-mask']} block lg:hidden lg:relative w-full overflow-hidden `}>
              <img className="w-full object-cover absolute" src="/home/pexels-fauxels-3183183.svg" alt="" />
            </div>
          </div>
          <div className="absolute top-0 w-full h-full flex justify-center items-center">
            <Image 
              // className={`${styles.bgBanner} flex justify-center items-center top-0 w-full h-full`}
              src='/banner/image.jpg'
              alt="Picture of xarehub"
              layout='fill'
              objectFit='cover'
              priority={true}
            />
            <MomerizedBannerCarousel />
          </div>
        </div>
      }
    </section>
  )
};

Header.propTypes = {
  content: string,
};

export default Header;
