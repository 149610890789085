import React, { useRef, useState, useCallback } from 'react';
import { SearchIcon, XIcon } from '@heroicons/react/solid'
import { groupBy, debounce, throttle } from 'lodash'

const Search = ({ keyword, onSetKeyword }) => {
  const ref = useRef()
  const clearSearch = () => {
    ref.current.value = ''
    onSetKeyword(ref.current.value)
  }

  const delayedQuery = useCallback(debounce(q => {
    console.log('query')
    onSetKeyword(q)
  }, 300), []);
  const onChange = e => {
    console.log('type search')
    delayedQuery(ref.current.value);
  };
  return (
    <div className="search rounded flex flex-row items-center w-1/2 m-auto my-5 bg-blue">
      <SearchIcon className={`h-6 w-6 mx-3 text-white`} />
      <input
        className="w-full py-2 focus:outline-none text-white rounded"
        placeholder="請輸入關鍵字"
        ref={ref}
        type="text"
        onChange={onChange}
      // onKeyDown={handleSearch}
      />
      {keyword && <button type="button" onClick={clearSearch}><XIcon className={`h-6 w-6 mx-3 text-white`} /></button>}
    </div>
  )
}

export default Search;
