import { TimePicker, DatePicker, Form, Input, Select, Option, Button, Checkbox } from 'antd';


const AntDropDown = ({ icon, text }) =>
  <Form.Item name="location" rules={[{ required: true, message: '請選擇您的服務單位!' }]}>
    <Select
      placeholder="人數"
      onChange={() => { }}
      // allowClear
      // bordered={false}
      clearIcon={{}}
      showArrow={false}
    >
      <Select.Option value="person">忠孝復興館</Select.Option>
      <Select.Option value="enterprise">忠孝復興館</Select.Option>
    </Select>
  </Form.Item>

export default AntDropDown;
