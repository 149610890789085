
const Page = ({ title, content }) => {
  return (
    <>
      <h1 className="text-black text-2xl md:text-4xl pt-10 md:pt-20 pb-5 text-center md:text-left">{title}</h1>
      {/* <p className="text-white text-md md:text-lg">{content}</p> */}
      <div
        className="text-black text-md md:text-lg pt-2 pb-10 unstyled"
        // className={styles.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {/* <pre className="text-white text-md md:text-lg pt-2 pb-10">
        {content}
      </pre> */}
    </>
  )
}

export default Page;
