import React from 'react';
import { MAP_SETTINGS, GOOGLE_MAP_URL } from '@/lib/constants'
import GoogleMapReact from 'google-map-react';
import Image from "next/image";
import styles from '@/styles/Search.module.css';

const {
  MARKER_SIZE,
  DEFAULT_ZOOM,
  DEFAULT_CENTER,
  DEFAULT_MAP_OPTIONS,
  // PIXEL_OFFSET,
  // DIRECTIONS_OPTIONS,
} = MAP_SETTINGS

const K_MARGIN_TOP = 30;
const K_MARGIN_RIGHT = 30;
const K_MARGIN_BOTTOM = 30;
const K_MARGIN_LEFT = 30;

const K_HOVER_DISTANCE = 30;

const Marker = ({ text }) => <div className={styles.marker}></div>;

const GoogleMapContainer = ({
  results,
  origins,
  destinations,
  center = {
    lat: 25.037304685601484,
    lng: 121.54564910768744
  }
}) => {
  const mapRef = React.useRef(null)
  const defaultProps = {
    center,
    zoom: 15
  };
  console.log(process.env.GATSBY_GOOGLE_KEY)
  console.log(results)

  const _onBoundsChange = (center, zoom, bounds, marginBounds) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({ center, zoom, bounds, marginBounds });
    } else {
      this.props.onCenterChange(center);
      this.props.onZoomChange(zoom);
    }
  }

  const _onChildClick = (key, childProps) => {
    const markerId = childProps.marker.get('id');
    const index = this.props.markers.findIndex(m => m.get('id') === markerId);
    if (this.props.onChildClick) {
      this.props.onChildClick(index);
    }
  }

  const _onChildMouseEnter = (key, childProps) => {
    const markerId = childProps.marker.get('id');
    const index = this.props.markers.findIndex(m => m.get('id') === markerId);
    if (this.props.onMarkerHover) {
      this.props.onMarkerHover(index);
    }
  }

  const _onChildMouseLeave = (/* key, childProps */) => {
    if (this.props.onMarkerHover) {
      this.props.onMarkerHover(-1);
    }
  }

  const _onBalloonCloseClick = () => {
    if (this.props.onChildClick) {
      this.props.onChildClick(-1);
    }
  }

  // const _distanceToMouse = customDistanceToMouse;


  const Markers = () => <>
    {results.map((result = { geo: {} }, idx) => (
      <Marker
        key={idx}
        // lat={result.geo.lat}
        lat="25.041693695588783"
        // lng={result.geo.lng}
        lng="121.54381490834052"
        text={result.name}
      />
    ))}
  </>

  return (
    <div className={`w-full`}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDArHGBO5XMBHdg8hemCkzuKu_B1eY638M' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      // onBoundsChange={_onBoundsChange}
      // onChildClick={_onChildClick}
      // onChildMouseEnter={_onChildMouseEnter}
      // onChildMouseLeave={_onChildMouseLeave}
      // margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
      // hoverDistance={K_HOVER_DISTANCE}
      // distanceToMouse={_distanceToMouse}
      >
        {results.map((result = { geo: {} }, idx) => (
          <Marker
            key={idx}
            lat={result.geo.lat}
            // lat="25.041693695588783"
            lng={result.geo.lng}
            // lng="121.54381490834052"
            text={result.name}
          />
        ))}
      </GoogleMapReact>
    </div>
  )

}


export default GoogleMapContainer;
