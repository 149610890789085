import React, { useEffect, useState } from 'react';
import styles from '@/styles/Home.module.css';
import { Row, Col, DatePicker, TimePicker, Form, Input, Select, Option, Checkbox, Spin } from 'antd';
import { DownCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import { actionTypes, fetchSearchResult, fetchLocationList, fetchZoneList, searchByFilters } from '@/lib/actions';
import { connect, useSelector } from 'react-redux';
import { DATE_FORMAT, DATETIME_FORMAT, LIVING_AREA, ATTENDEE_TYPE, ZIP, DROP_LIST } from '@/lib/constants';
import { disabledDate, disabledTime, disabledDateOnlyOneYear } from '@/lib/utils';
import moment from 'moment';
import { isEmpty, omit } from 'lodash';
import { useRouter } from 'next/router';
import { DPicker, DTPicker } from '@/components/widgets/Button/DatePicker';

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const SearchPanel = ({ locationList, zoneList, boundFetchLocationList, boundFetchZoneList, filters, handleClick, children, boundSearchByFilters }) => {
  const router = useRouter()
  const size = "large"

  const [_form, setLocalForm] = useState(filters);
  const [form] = Form.useForm();
  const [width, height] = useWindowSize()

  const isMobileWindow = width < 768;
  const isDisabled = form.getFieldValue('type') == 3
  const isPeopleDisabled = form.getFieldValue('type') == 2
  const submitToChildFormData = isMobileWindow ? { ..._form, date: moment(_form.date).format('YYYY-MM-DD'), datetime: moment(_form.date).format('HH:mm') } : _form;

  useEffect(() => {
    boundFetchLocationList()
    boundFetchZoneList()
  }, []);

  useEffect(() => {
    console.log('_form', _form)
    setLocalForm({
      ..._form,
      date: _form.date_day && _form.date_time ? moment(`${_form.date_day} ${_form.date_time}`).format(`${DATE_FORMAT} ${DATETIME_FORMAT}`) : undefined
    })
  }, [_form.date_day, _form.date_time])

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (name) => (v) => {
    setLocalForm({ ..._form, [name]: v })
    if (name === 'type' && v === 1) {
      console.log('select')
      form.setFieldsValue({ people: 1 })
    }
  }

  const onFinish = (values) => {
    console.log('Success:', values);
    // const data = form.getFieldValue('type') == 3 ? omit(values, ['date', 'datetime']) : values
    handleClick({
      ...values,
      date: isMobileWindow ? moment(_form.date).format('YYYY-MM-DD') : moment(values.date).format('YYYY-MM-DD'),
      datetime: isMobileWindow ? moment(_form.date).format('HH:mm') : moment(values.datetime).format('HH:mm')
    })
  };

  return (<>
    {locationList.length && zoneList.length ?
      <div className={`search-panel flex flex-col lg:flex-row rounded-lg px-2 py-2 w-full lg:w-full `}>
        <Form
          name="basic"
          form={form}
          initialValues={{
            ...filters,
            date: filters.date ? moment(filters.date) : '',
            datetime: filters.datetime ? moment(filters.datetime, DATETIME_FORMAT) : ''
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: '100%' }}
        >
          <div className={`${styles['search-tool']} search-tool-panel rounded-lg px-2 py-2`}>
            <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }} justify="space-around" align="middle">
              <Col className="gutter-row" span={7} xs={24} xl={7}>
                <Form.Item name="where">
                  <Select
                    placeholder="選擇你想要的地點"
                    onChange={onChange('where')}
                    size={size}
                    autoFocus={false}
                    suffixIcon={
                      <div className="w-3 h-5 relative m-auto location-icon">
                        {DROP_LIST[0].icon}
                      </div>
                    }
                    dropdownMenuStyle={{ borderRadius: '10px' }}
                  >
                    {locationList.map((location, idx) => <Select.Option key={idx} value={location.location_no}>{location.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={7} xs={24} xl={7}>
                <Form.Item name="type">
                  <Select
                    placeholder="空間使用"
                    onChange={onChange('type')}
                    // allowClear
                    // bordered={false}
                    // prefix={<>
                    //   <img src="/icons/Icon_User_Blue.svg" />
                    // </>}
                    size={size}
                    // clearIcon={{}}
                    // showArrow={false}
                    autoFocus={false}
                    // suffixIcon={<DownCircleTwoTone />}
                    suffixIcon={
                      <div className="w-5 h-5 relative m-auto space-icon">
                        {DROP_LIST[1].icon}
                      </div>
                    }
                    // dropdownClassName={styles.dropdown}
                    // style={{ borderRadius: '10px' }}
                    dropdownMenuStyle={{ borderRadius: '10px' }}
                  // dropdownStyle={{ borderRadius: '10px' }}
                  >
                    {zoneList.map((zone, idx) => <Select.Option key={idx} value={zone.zone_id}>{zone.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
              {isMobileWindow && <Col className="gutter-row block md:hidden" span={4} xs={24} xl={4} >
                <Form.Item>
                  <DPicker
                    name="date"
                    space={false}
                    defaultValue={_form}
                    onFinish={setLocalForm}
                    hourPlaceholder="選擇日期"
                    minutesPlaceholder="選擇時段"
                    suffixIcon={
                      <div className="w-5 h-5 relative date-icon" >
                        {DROP_LIST[2].icon}
                      </div>
                    }
                    suffixSecondIcon={
                      <div className="w-5 h-5 relative date-icon" >
                        {DROP_LIST[3].icon}
                      </div>
                    }
                  />
                </Form.Item>
              </Col>}

              {!isMobileWindow && <Col className="gutter-row hidden md:block" span={4} xs={24} xl={4} >
                <Form.Item
                  // label="預約日期"
                  name="date"
                // rules={[{ required: true, message: '請選擇您的預約日期!' }]}
                // style={{ display: 'inline-block', width: 'calc(100%)' }}
                >
                  <DatePicker
                    disabledDate={disabledDateOnlyOneYear}
                    disabled={isDisabled}
                    format={DATE_FORMAT}
                    placeholder="日期"
                    style={{ width: '100%' }}
                    onChange={(date) => setLocalForm({ ..._form, date: moment(date).format(DATE_FORMAT) })}
                    size={size}
                    suffixIcon={
                      <div className="w-5 h-5 relative m-auto date-icon" >
                        {DROP_LIST[2].icon}
                      </div>
                    }
                  />
                </Form.Item>
              </Col>}

              {!isMobileWindow && <Col className="gutter-row hidden md:block" span={3} xs={24} xl={3}>

                <Form.Item
                  // label="預約時段"
                  name="datetime"
                // moment().format('H:mm:ss');
                // rules={[{ required: true, message: '請選擇您的預約時段!' }]}
                // style={{ display: 'inline-block', width: 'calc(50%)' }}
                >
                  <TimePicker
                    disabledHours={disabledTime}
                    disabled={isDisabled}
                    format={DATETIME_FORMAT}
                    placeholder="時段"
                    minuteStep={30}
                    onChange={(datetime) => setLocalForm({ ..._form, datetime: moment(datetime).format(DATETIME_FORMAT) })}
                    style={{ width: '100%' }}
                    size={size}
                    suffixIcon={
                      <div className="w-4 h-4 relative m-auto">
                        {DROP_LIST[3].icon}
                      </div>
                    }
                  />
                </Form.Item>
              </Col>}

              <Col className="gutter-row" span={3} xs={24} xl={3}>
                <Form.Item name="people">
                  <Select
                    placeholder="人數"
                    // defaultValue={filters.where}
                    onChange={onChange('people')}
                    // allowClear
                    // bordered={false}
                    prefix={<>
                      <img src="/icons/Icon_User_Blue.svg" />
                    </>}
                    size={size}
                    // clearIcon={{}}
                    // showArrow={false}
                    autoFocus={false}
                    // suffixIcon={<DownCircleTwoTone />}
                    suffixIcon={<div className="w-3 h-3 relative m-auto">
                      {DROP_LIST[4].icon}
                    </div>}
                    disabled={isPeopleDisabled}
                  // dropdownClassName={styles.dropdown}
                  // style={{ borderRadius: '10px' }}
                  // dropdownMenuStyle={{ borderRadius: '10px' }}
                  // dropdownStyle={{ borderRadius: '10px' }}
                  >
                    {[...Array(10).keys()].map((location, idx) => <Select.Option key={idx} value={idx + 1}>{idx + 1}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Form.Item>
            {children(submitToChildFormData)}
          </Form.Item>
        </Form>
      </div> : <div className="flex justify-center items-center container m-auto my-10 px-5 lg:px-0">
        {/* <Skeleton active paragraph={{ rows: 4 }} /> */}
        <Spin indicator={antIcon} />
      </div>}
  </>
  )
}

const mapStateToProps = (state => {
  const { locationList, zoneList } = state.reducer;
  return {
    locationList,
    zoneList
  }
})

const mapStateToDispatch = (dispatch) => {
  return {
    boundSearchByFilters: (filters) => dispatch(searchByFilters(filters)),
    // 選擇據點
    boundFetchLocationList: () => dispatch(fetchLocationList()),
    // 選擇室內區域
    boundFetchZoneList: () => dispatch(fetchZoneList()),

  }
}

export default connect(mapStateToProps, mapStateToDispatch)(SearchPanel);
