import React, { useState } from 'react';
import { SearchPanel } from '@/components/index';
import { DEFAULT_SEARCH_FILTERS, DATE_FORMAT, DATETIME_FORMAT } from '@/lib/constants';
import { useRouter } from 'next/router';
import { toUrlParamsString } from '@/lib/utils';
import { actionTypes, fetchSearchResult, fetchLocationList, fetchZoneList } from '@/lib/actions';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';

const SearchTool = ({ search, text = "搜尋", type = "submit", children, handleClick }) => {
  const router = useRouter()
  // const [filters, setFilters] = useState(search);
  // console.log('filters!!!')
  // console.log(filters)
  const handleRedirectLinkClick = (form) => (e) => {
    // console.log(router.pathname === '/')
    console.log(form)
    // console.log(search)
    // console.log('search')
    if (router.pathname === '/') {
      if (form?.date) moment(form.date).format(DATE_FORMAT)
      if (form?.datetime) moment(form.datetime).format(DATETIME_FORMAT)
      // console.log(search)
      const url = toUrlParamsString(`/search`, form);
      router.push(url)
    }
  }
  const onClick = (form) => handleRedirectLinkClick(form);
  // const handleClick = (filters) => setFilters(filters)

  return (
    <div className="flex flex-col justify-center items-center gap-5 lg:gap-10 w-10/12 pt-20 lg:pt-0 z-20">
      {children}
      <SearchPanel filters={search} handleClick={handleClick}>
        {(formValues) =>
          <div className="w-full my-2  px-3 text-center">
            <button type={type} className="bg-blue m-auto text-white rounded-lg w-full lg:w-64 py-2 " onClick={type === 'button' ? onClick(formValues) : null}>{text}</button>
          </div>}
      </SearchPanel>
    </div>
  )
}


const mapStateToProps = (state => {
  const { locationList, zoneList } = state.reducer;
  return {
    locationList,
    zoneList
  }
})

const mapStateToDispatch = (dispatch) => {
  return {
    // 選擇據點
    boundFetchSearchResult: (payload) => dispatch(fetchSearchResult(payload)),
    boundFetchLocationList: () => dispatch(fetchLocationList()),
    // 選擇室內區域
    boundFetchZoneList: () => dispatch(fetchZoneList()),

  }
}

export default connect(mapStateToProps, mapStateToDispatch)(SearchTool);
