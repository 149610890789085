import React, { useEffect, useRef, useState } from 'react';
import { Logo } from '@/components/index';
import Link from 'next/link';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from './Nav.module.css';

const MeunItem = () => {
  const links = [
    {
      name: '聚落據點',
      href: '/location'
    },
    // {
    //   name: '思源黑體',
    //   href: '/membership'
    // },
    {
      name: '會籍方案',
      href: '/membership'
    },
    {
      name: '預約參觀',
      href: '/privatespace_reservation'
    },
    {
      name: '免費體驗',
      href: '/hotdesk_freetrial'
    },
    // {
    //   name: '下載APP',
    //   href: '/location'
    // },
  ]
  const style = `header-nav text-white font-normal text-[16px] py-2 v1-text-white-shadow`
  return (
    <div className="flex flex-col lg:flex-row gap-0 lg:gap-8">
      {links.map((item, idx) => (
        <a key={idx} className={style} href={item.href}>
          {item.name}
        </a>
      ))}
      <Link href="http://onelink.to/ezj8th">
        <a className={`${style} text-green`} target="_blank">
          下載APP
        </a>
      </Link>
    </div>
  )
}

const AppLink = () => {
  return (
    <Link href="/app_feature">
      <a
        className="header-btn px-5 py-2 rounded-sm bg-white text-black focus:outline-none hover:text-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
      >免費體驗</a>
    </Link>
  )
}

const SettingsItem = ({ handleOpenDropDown }) => {
  return (
    <>
      <div className="ml-3 relative">
        <div className="relative inline-block text-left">
          <div>
            <button type="button" onClick={handleOpenDropDown} className="flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500" id="options-menu">
              <svg width={20} fill="currentColor" height={20} className="text-gray-800" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1523 1339q-22-155-87.5-257.5t-184.5-118.5q-67 74-159.5 115.5t-195.5 41.5-195.5-41.5-159.5-115.5q-119 16-184.5 118.5t-87.5 257.5q106 150 271 237.5t356 87.5 356-87.5 271-237.5zm-243-699q0-159-112.5-271.5t-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5 271.5-112.5 112.5-271.5zm512 256q0 182-71 347.5t-190.5 286-285.5 191.5-349 71q-182 0-348-71t-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                </path>
              </svg>
            </button>
          </div>
          <div className={`${isOpenDropdown ? '' : 'hidden'} origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5`}>
            <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a href="#" className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                <span className="flex flex-col">
                  <span>
                    Settings
                  </span>
                </span>
              </a>
              <a href="#" className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                <span className="flex flex-col">
                  <span>
                    Account
                  </span>
                </span>
              </a>
              <a href="#" className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                <span className="flex flex-col">
                  <span>
                    Logout
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const HamburgerButton = ({ isOpenMobileDropdown, handleOpenMobileDropDown }) => {
  return (
    <button onClick={handleOpenMobileDropDown} className={`text-white dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none`} aria-label="menu">
      {/* <svg width={20} height={20} fill="currentColor" className="h-8 w-8" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z">
        </path>
      </svg> */}
      {isOpenMobileDropdown ? (
        <XIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
      )}
    </button>
  )
}
const Nav = ({ isOpenMobileDropdown, handleOpenMobileDropDown, handleOpenDropDown, isSticky }) => {
  const menuRef = useRef();
  return (
    <nav className={`${styles['bg-header-color']} text-white bg-opacity-100 ${isSticky ? `mt-0` : `nav-top-margin  lg:bg-opacity-0  text-black ${styles['bg-header-transparent']}`}  ${styles['sticky-inner']} font-bold transition duration-500 ease-in-out`}>
      <div className="container m-auto px-5">
        <div className={`${styles['nav-container']} flex items-center justify-between`}>
          <Logo isSticky={isSticky} />
          <div className="hidden lg:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <MeunItem />
            </div>
          </div>
          <div className="-mr-2 flex lg:hidden">
            <HamburgerButton isOpenMobileDropdown={isOpenMobileDropdown} handleOpenMobileDropDown={handleOpenMobileDropDown} />
          </div>
        </div>
      </div>
      <div className={`relative z-20 max-h-0 overflow-hidden transition-all duration-300 ease-in-out`} style={{ maxHeight: isOpenMobileDropdown ? menuRef.current.scrollHeight : '' }}>
        <div ref={menuRef} className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-center">
          <MeunItem />
          {/* <div className="p-5">
            <AppLink />
          </div> */}
        </div>
      </div>
    </nav>
  )
}

export default Nav;
