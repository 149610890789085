import Image from 'next/image';
import styles from '@/styles/Search.module.css'
import router from 'next/router';
import { toUrlParamsString } from '@/lib/utils';
import { isNull } from 'lodash';

const ResultItem = ({ result, search }) => {
  const url = toUrlParamsString(`/hotdesk_freetrial`, { location_id: result.location_id });
  const onClick = () => {
    console.log(search)

    const url = toUrlParamsString(`${result.cta === '免費體驗' ? '/hotdesk_freetrial' : '/privatespace_reservation'}`, { ...search, id: result.id });
    router.push(result.ctaLink ? result.ctaLink : url)
  }
  return (
    // <div className={`${styles.resultItem} round-tr flex flex-col lg:flex-row ${result.dataSource === 'user' ? 'border-2 border-double' : null} ${!result.remainingSeat && !isNull(result.remainingSeat) ? 'opacity-50' : ''} `}>
    <div className={`${styles.resultItem} round-tr flex flex-col lg:flex-row ${result.dataSource === 'user' ? 'border-2 border-double' : null} `}>
      <div className="relative w-full lg:w-1/2">
        {/* {!isNull(result.remainingSeat) && <p className={styles.remainingSeat}>{result.remainingSeat ? `尚餘：${result.remainingSeat}座位` : `已額滿`}</p>} */}
        <div className="block lg:hidden h-full">
          {/* <Image width="150" height="110" layout="responsive" objectFit="cover" src={result.cover} alt={result.name} loading="eager" /> */}
          <img src={result.cover} className="w-full h-full object-cover" alt="" />
        </div>
        <div className="hidden lg:block h-full">
          {/* <Image width="250" height="210" layout="responsive" objectFit="cover" src={result.cover} alt={result.name} loading="eager" /> */}
          <img src={result.cover} className="w-full h-full object-cover" alt="" />
        </div>
      </div>
      <div className="flex flex-col justify-around w-full lg:w-1/2 p-2">
        <div className="lg:border-b-2">
          <div>
            <p className="title-s3 font-bold">{result.name}</p>
            <p className="title-s3 line-clamp-2">{result.location}</p>
            <p className={styles.availablePeople}>可容納人數 {result.availablePeople}</p>
          </div>
          {result.price && <div className="flex flex-row justify-start lg:justify-end">
            <p className="text-blue-c100 pb-2 title-s3">NT {result.price} <span className="p-s1">{result.unit}</span></p>
          </div>}
        </div>
        <div className="flex flex-row justify-end gap-2">
          {/* <button className="bg-purple text-blue-c100 rounded-lg p-2 order-2 w-1/2 lg:w-20 lg:order-1">查看內容</button> */}
          <a className="bg-purple text-blue-c100 rounded-lg p-2 order-2 w-1/2 lg:w-20 lg:order-1 text-center" href={`/space_detail/${result.id}`}>查看內容</a>
          {result.cta && <button className="bg-blue-c100 text-white rounded-lg p-2 order-1 w-1/2 lg:w-20 lg:order-2" onClick={onClick}>{result.cta}</button>}
        </div>
      </div>
    </div>
  )
}



export default ResultItem;
