import Head from 'next/head'
import { META } from '@/lib/constants'

export default function Meta() {
  return (
    <Head>
      <title>{META.title}</title>
      <meta name="viewport" content={META.viewport} />
      <meta name="title" content={META.title} />
      <meta name="description" content={META.description} />
      <meta name="keywords" content={META.keywords} />
      <meta property="article:publisher" content={META.fbLink} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={META.title} />
      <meta property="og:description" content={META.description} />
      <meta property="og:image" content={META.previewImage} />
      <meta propertyimage_src="og:url" content={META.url} />
      <meta property="og:site_name" content={META.title} />
      <link rel="" href={META.previewImage} />
      <link rel="icon" type="image/png" href="/xarehub_favicon.png" />


      {/* <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#000000"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" /> */}
      {/* <meta
        name="description"
        content={`A statically generated blog example using Next.js and ${CMS_NAME}.`}
      /> */}
      {/* <meta property="og:image" content={HOME_OG_IMAGE_URL} /> */}

      <link rel="shortcut icon" href="/favicon/favorite.png" type="image/x-icon" />
      <link rel="apple-touch-icon" href="/favicon/favorite.png" />
      {/* <meta name="msapplication-TileColor" content="#000000" /> */}
      {/* <meta name="msapplication-config" content="/favicon/browserconfig.xml" /> */}
      {/* <meta name="theme-color" content="#000" /> */}
      {/* <link rel="alternate" type="application/rss+xml" href="/feed.xml" /> */}
    </Head>
  )
}

