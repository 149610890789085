import Image from 'next/image';
import styles from '@/styles/Search.module.css'
import { CaretRightOutlined } from "@ant-design/icons";
import Router from 'next/router';

const LocationItem = ({ result }) =>
  <div className={`${styles.resultItem} round-tr flex flex-col lg:flex-row`}>
    <div className="relative w-full lg:w-1/2">
      <div className="block lg:hidden">
        <Image width="150" height="110" layout="responsive" objectFit="cover" src={result.cover} alt={result.name} priority={true} />
        {/* <img src={result.cover} /> */}
      </div>
      <div className="hidden lg:block">
        <Image width="250" height="210" layout="responsive" objectFit="cover" src={result.cover} alt={result.name} priority={true} />
        {/* <img src={result.cover} /> */}
      </div>
    </div>
    <div className="flex flex-col justify-around w-full lg:w-1/2 p-3">
      <div className="">
        <div>
          <p className={styles.title}>{result.name}</p>
          <p className={styles.desc}>{result.address}</p>
          <p className={styles.desc}>{result.note}</p>
          <p className={styles.desc}>{result.businessHour}</p>
          {/* <p className={styles.title}>路名交叉-大樓名稱</p>
          <p className={styles.desc}>台北市大安區南京東路 66 號 8 樓</p>
          <p className={styles.desc}>捷運南京復興站 1 號出口</p>
          <p className={styles.desc}>週一至周五 08 : 00~22 : 00</p> */}
        </div>
      </div>
      <div className="flex flex-row justify-end gap-2">
        <button className="text-blue-c100 rounded-lg p-2 order-2 w-1/2 lg:order-1 flex flex-row justify-end items-center" onClick={() => Router.push(`/location/${result.id}`)}>暸解更多<CaretRightOutlined /></button>
      </div>
    </div>
  </div>


export default LocationItem;
