import React, { useState } from 'react';
import styles from './SelectSeatModal.module.scss';
import { XIcon, SelectorIcon, ChevronDownIcon, CalendarIcon } from '@heroicons/react/solid'
import { LoadingOutlined } from '@ant-design/icons';
import { connect, useSelector } from 'react-redux';
import { actionTypes } from '@/lib/actions';
import { SEAT_STATUS } from '@/lib/constants';
import { Pagination, Skeleton, Spin, Space, Result } from 'antd';
import { isUndefined } from 'lodash';
import Image from 'next/image';

const SelectSeatModal = ({ spaces, isOpenSelectSeatModal, closeSelectSeatModal, trial, selectSeat }) => {
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [selectedCode, setSelectedCode] = useState(null);
  const [isShowMap, setIsShowMap] = useState(false);
  // const seats = [...new Array(10).fill({ isSelected: true }), ...new Array(10).fill({ isSelected: false })]
  const onClick = () => {
    document.body.style.overflow = 'unset';
    selectSeat({ selectedSeat, selectedCode })
    closeSelectSeatModal({ selectedSeat, selectedCode })
  }
  const onClose = () => {
    closeSelectSeatModal({ selectedSeat: null, selectedCode: null })
    document.body.style.overflow = 'unset';
  }
  const handleSelectSeat = (seat) => (e) => {
    console.log('select')
    console.log(seat)
    setSelectedSeat(seat.staff_id)
    setSelectedCode(seat.staff_code)
  }

  const handleReservedSeat = (seat) => (e) => console.log(seat)
  console.log(selectedSeat)
  console.log(selectedCode)

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return <>
    {isOpenSelectSeatModal &&
      <div className={styles.wrapper} onClick={() => {
        // event.stopPropagation();
        // closeSelectSeatModal({ selectedSeat: null })
      }}>
        <div className="relative flex flex-col items-center bg-white rounded-md px-5 w-11/12 lg:w-[600px] p-5 lg:p-5">
          <p className="text-blue p-s2 pb-2">選擇座位</p>
          {/* <p className="p-s3 text-gray-10 pb-2">請選擇您要預約的座位</p> */}
          <p className={`${styles.arenaMapBtn} text-blue p-s3 bg-purple px-2 py-1 mb-3 cursor-pointer`} onClick={() => setIsShowMap(!isShowMap)}>
            {isShowMap ? <div className="flex justify-center items-center gap-2 p-1">
              <div className="w-2 h-2 rotate-180">
                <Image width="6" height="4" layout="responsive" src={`/icons/triangle.svg`} alt={""} />
              </div>
              <span className="text-[16px]">點擊收起座位位置圖</span>
            </div> : <div className="flex justify-center items-center gap-2 p-1">
              <div className="w-2 h-2 pt-1">
                <Image width="6" height="4" layout="responsive" src={`/icons/triangle.svg`} alt={""} />
              </div>
              <span className="text-[16px]">點擊展開座位位置圖</span>
            </div>}
          </p>
          {isShowMap ? <div className="w-full lg:w-4/5 my-5">
            <Image width="750" height="500" layout="responsive" src={`/seat-map.jpg`} alt={""} />
          </div> : null}
          <div className={`w-full lg:w-10/12 flex flex-row justify-center items-start lg:items-start py-0 overflow-y-auto  ${isShowMap ? ' h-32' : styles.isShowMap}`}>
            {!isUndefined(spaces) ? spaces.length ? <ul className="flex flex-row flex-wrap justify-center items-center">
              {/* {seats.map((seat, idx) => <div key={idx} className={`${styles.seat} ${seat.isSelected ? styles.selected : ''} ${selectedSeat === idx + 1 ? styles.active : ''} text-gray-10`} onClick={() => setSelectedSeat(idx + 1)}>{idx + 1}</div>)} */}
              {spaces.map((seat, idx) => <div key={idx} className={`${styles.seat} ${seat.status === 'reserved' ? styles.selected : ''} ${selectedCode === seat.staff_code ? styles.active : ''} text-gray-10`} onClick={seat.status === 'reserved' ? handleReservedSeat(seat) : handleSelectSeat(seat)}>{seat.staff_code}</div>)}
            </ul> : '尚無座位' : <div className="flex justify-center items-center container m-auto my-10 px-5 lg:px-0">
              {/* <Skeleton active paragraph={{ rows: 4 }} /> */}
              <Spin indicator={antIcon} />
            </div>}
          </div>
          <div className="flex flex-row gap-5 justify-center items-center py-5">
            {Object.keys(SEAT_STATUS).map((status, idx) => <p key={SEAT_STATUS[status].key} className="text-gray-10 flex flex-row gap-1"><div className={`${SEAT_STATUS[status].key === 'now' ? 'bg-blue' : styles.selected} rounded-full w-5 h-5`}></div>{SEAT_STATUS[status].label}</p>)}
            {/* <p className="text-gray-10 flex flex-row gap-1"><div className="bg-blue rounded-full w-5 h-5"></div> 您的位置</p> */}
            {/* <p className="text-gray-10 flex flex-row gap-1"><div className={styles.selectedSeat}></div>已預約位置</p> */}
          </div>
          <button className="bg-blue text-white px-5 py-2 rounded-md" onClick={onClick}>確認</button>
          <button className=" absolute right-5 top-5 text-gray-10 w-5 h-5" onClick={onClose}><XIcon /></button>
        </div>
      </div>
    }
  </>
}

const mapStateToProps = (state => {
  const { spaces, isOpenSelectSeatModal, trial } = state.reducer;
  return {
    spaces,
    isOpenSelectSeatModal,
    trial,
  }
})

const mapStateToDispatch = (dispatch) => {
  return {
    closeSelectSeatModal: (payload) => dispatch({ type: actionTypes.CLOSE_SELECT_SEAT_MODAL, payload }),
    selectSeat: (payload) => dispatch({ type: actionTypes.UPDATE_FREE_TRIAL, payload }),
  }
}

export default connect(mapStateToProps, mapStateToDispatch)(SelectSeatModal);
