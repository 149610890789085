import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Space, Toast, Picker } from 'antd-mobile'
import { Tag } from 'antd'
import moment from 'moment';
import { DATE_FORMAT, DATETIME_FORMAT, DATEHOUR_FORMAT, basicColumns } from '@/lib/constants';
import styles from './DatePicker.module.css';
import { range } from 'lodash';
// 取得完整 YYYY/MM/DD HH:mm 時間。目前是拆分成 date_hour, date_minutes.
export const DPicker = ({
  space = true,
  name,
  hourPlaceholder,
  minutesPlaceholder,
  disabledDateOption = false,
  isEnableDatePick = true,
  isDisableTimePick = false,
  suffixIcon,
  suffixSecondIcon,
  defaultValue = {},
  defaultDateHour = '',
  defaultDateMinutes = '',
  showTag = false,
  onFinish = () => { },
  timeRange = basicColumns,
  onChange,
  onSelect
}) => {
  console.log('defaultValue', defaultValue)
  console.log('name', name)
  const dateDay = `${name}_day`
  const dateTime = `${name}_time`
  // const defaultDateMoment = moment(defaultValue[name])
  // console.log('defaultDateMoment', defaultDateMoment)
  const dateHour = defaultValue[dateDay] ? moment(defaultValue[dateDay]).format(DATE_FORMAT) : ''
  console.log('dateHour', dateHour)
  const dateMinutes = defaultValue[dateTime] ? defaultValue[dateTime].split(':') : ''
  console.log('dateMinutes', dateMinutes)
  const disableDatePickerColumn = ['end_date'];


  const PickerButtonStyle = { borderRadius: '10px' }

  const [visible, setVisible] = useState(false)
  const [visiblePicker, setVisiblePicker] = useState(false)
  console.log('s dateHour', dateHour)

  // const [val, setVal] = useState(dateHour);
  // const [valPicker, setValPicker] = useState(dateMinutes);
  console.log('e dateHour', dateHour)
  // console.log('val', val)
  // useEffect(() => {
  //   const OUTPUT_FORMAT = `${dateHour} ${dateMinutes[0]}:${dateMinutes[1]}`
  //   // console.log('val', val)
  //   console.log('dateMinutes', dateMinutes)
  //   console.log('result', `${dateHour}:${dateMinutes}`)
  //   console.log({ ...defaultValue, [name]: dateHour && dateMinutes ? OUTPUT_FORMAT : null })
  //   onFinish({ ...defaultValue, [name]: dateHour && dateMinutes ? OUTPUT_FORMAT : null })
  //   // setVal(dateHour)
  //   // setVal(dateMinutes)
  // }, [val, valPicker])
  const onTimePickerSelect = value => console.log(value)
  const Wrapped = ({ children, padding }) => padding ? <Space direction="vertical" className="w-full">{children}</Space> : <>{children}</>
  const onConfirm = value => onFinish({ ...defaultValue, [dateDay]: moment(value).format(DATE_FORMAT), [dateTime]: dateMinutes ? `${dateMinutes[0]}:${dateMinutes[1]}` : undefined })
  const onTimePickerConfirm = (value) => onFinish({ ...defaultValue, [dateTime]: `${value[0]}:${value[1]}` })
  const PickerButton = ({ children, suffixIcon, ...props }) =>
    <Button
      {...props}
    >
      <div className="flex flex-row items-center gap-2 h-6 ">
        {suffixIcon}
        {children}
      </div>
    </Button>
  const MomorizedPickerButton = React.memo(PickerButton)
  const DateDropdown = () =>
    <Wrapped padding={true}>
      <MomorizedPickerButton
        onClick={() => setVisible(true)}
        className="w-full text-blue text-left"
        style={PickerButtonStyle}
        suffixIcon={suffixIcon}
        disabled={disableDatePickerColumn.indexOf(name) !== -1}
      >
        <div className="w-full flex justify-between">
          <p className={`${dateHour ? 'text-black' : styles.placeholder}`}>{dateHour ? dateHour : hourPlaceholder}</p>
          {dateHour && name === 'end_date' && showTag ? <span className="text-sm">離場時間</span> : null}
          {dateHour && name === 'date' && showTag ? <span className="text-sm">入場時間</span> : null}
        </div>
      </MomorizedPickerButton>
      <DatePicker
        min={new Date(moment().format(`${DATE_FORMAT} ${DATETIME_FORMAT}`))}
        max={new Date(moment().add(1, 'year').format(`${DATE_FORMAT} ${DATETIME_FORMAT}`))}
        defaultValue={defaultDateHour ? new Date(defaultDateHour) : ''}
        visible={visible}
        // precision='hour'
        precision='day'
        onClose={() => setVisible(false)}
        onConfirm={onChange ? onChange('datepicker', name, onConfirm) : onConfirm}
      />
    </Wrapped>

  const TimeDropdown = () =>
    <Wrapped padding={space}>
      <MomorizedPickerButton
        className="w-full text-blue text-left"
        style={PickerButtonStyle}
        suffixIcon={suffixSecondIcon}
        onClick={() => setVisiblePicker(true)}
        disabled={isDisableTimePick}
      >
        <p className={`${dateMinutes ? 'text-black' : styles.placeholder}`}>{dateMinutes ? `${Number(dateMinutes[0])} 點 ${Number(dateMinutes[1])} 分` : minutesPlaceholder}</p>
      </MomorizedPickerButton>
      {/* <DatePicker
        min={new Date(moment().format(`${DATE_FORMAT} ${DATETIME_FORMAT}`))}
        max={new Date(moment().add(1, 'year').format(`${DATE_FORMAT} ${DATETIME_FORMAT}`))}
        defaultValue={defaultDateAndTime ? new Date(defaultDateAndTime) : ''}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        precision='hour'
        onConfirm={onConfirm}
      /> */}
      <Picker
        columns={timeRange}
        visible={visiblePicker}
        value={dateMinutes}
        onClose={() => setVisiblePicker(false)}
        onSelect={onSelect ? onSelect('picker', name, onTimePickerSelect) : onTimePickerSelect}
        onConfirm={onChange ? onChange('picker', name, onTimePickerConfirm) : onTimePickerConfirm}
      />
    </Wrapped>
  return (
    <>
      {(isEnableDatePick || dateHour) && <DateDropdown />}
      <TimeDropdown />
    </>
  )
}
