// https://codesandbox.io/s/scroll-to-top-react-component-coderomeos-forked-msdn3?from-embed

import React, { useEffect, useState } from "react";
import { CheckIcon, SelectorIcon, ChevronUpIcon, CalendarIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import styles from './ScrollToTopBtn.module.css'
export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    console.log('scroll')
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="hidden lg:block lg:fixed z-20 bottom-96 right-10 scroll-to-top hover:cursor-pointer">
      {isVisible && (
        <div onClick={scrollToTop}>
          <ChevronUpIcon className="absolute bottom-2 right-2 z-10 w-10 text-white" />
          <img className={`w-14 h-14 text-blue-500 ${styles.svg} filter drop-shadow-2xl`} src="/hexagon-svgrepo-com.svg" alt="" />
        </div>
      )}
    </div>
  );
}
