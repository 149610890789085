import Image from 'next/image';
import styles from '@/styles/Home.module.css'

const PostItem = ({ post }) =>
  <div className={`${styles['post-item']} round-tr`}>
    <div className={styles['post-item-image']}>
      <div className="hidden lg:block">
        <Image width="376" height="277" layout="responsive" objectFit="cover" src={post.src} alt="" />
      </div>
      <div className="block lg:hidden">
        <Image width="335" height="213" layout="responsive" objectFit="cover" src={post.src} alt="" />
      </div>
      {/* <img src="/banner/pexels-ken-tomita-389818@2x.png" alt="" className="w-full object-cover" /> */}
    </div>
    <div className=" flex flex-col gap-2 lg:gap-5 p-4 pt-2 lg:py-5 text-left">
      <p className="title-s3 text-blue-color">{post.title}</p>
      <p className="p-s3 text-blue-color">{post.description}</p>
      <p className="text-blue p-s2 flex flex-row gap-2">
        <span>了解更多</span>
        <img className="transform rotate-90 w-3" src="/icons/iconmonstr-triangle-1.svg" alt="" />
      </p>
      {/* <BlueButton text="了解更多" /> */}
    </div>
  </div>


export default PostItem;
