import React, { useState, useEffect } from 'react';
import { Layout, CtaBtn, BannerCarousel, Dropdown, BlueButton, LeftTriangleOnTop, RightTriangleOnTop, DatePicker, DateTimePicker } from '@/components/index';
import { CheckIcon, SelectorIcon, ChevronDownIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import { OfficeBuildingIcon, CalendarIcon, ClockIcon, UserIcon } from '@heroicons/react/outline'
import styles from '@/styles/OfficeReservation.module.css'
import { Form, Input, Select, Option, Button, Checkbox, InputNumber, notification } from 'antd';
import { toUrlParamsString } from '@/lib/utils';
import FormData from 'form-data';
import { connect, useSelector } from 'react-redux';
import { actionTypes, fetchLocationList, fetchSpaceStatus, fetchJobIndustry, fetchCities } from '@/lib/actions';

const FillForm = ({ data = {}, columns, className, locationList, boundFetchLocationList, handleFormSubmit = () => { } }) => {
  // const [formData, setFormData] = useState(data);
  const onClick = () => {
    // console.log(formData)
    // setFormData(formData)
    handleFormSubmit(formData)
  }
  useEffect(() => {
    boundFetchLocationList()
  }, []);
  const onFinish = (values) => {
    console.log('Success:', values);
    // console.log(formData)
    console.log(JSON.stringify(values))
    // setFormData(values)
    // handleFormSubmit(values)
    // const GOOGLE_SHEET = 'https://script.google.com/macros/s/AKfycbyzjYViRXZ7Ayq6CHHFTMJOTFiCTGt2BLPXsvyY5UZfDukTo80WPIJPxczQdWQuRPa7/exec'
    const GOOGLE_SHEET = 'https://script.google.com/macros/s/AKfycbzkWFwz39fzl55C4Xzyej68IcGg25p1He0Lsb-xER4yP0NwfjyW3HWVkI_GwVDRGr81nA/exec'
    // const url = toUrlParamsString(GOOGLE_SHEET, values);
    let formData = new FormData();

    const queries = Object.keys(values);
    console.log(queries)
    queries.forEach((key, idx) => {
      console.log(key)
      console.log(values[key])
      formData.append(key, values[key]);
    });
    formData.append('Timestamp', new Date());
    console.log(formData)
    const res = fetch(GOOGLE_SHEET, { method: 'POST', body: formData })
      .then(response => {
        notification.success({
          message: '送出成功！',
          description:
            '感謝你的預約，將會有專人與你聯繫。',
          placement: 'bottomRight',
        });
        console.log('Success!', response)
      })
      .catch(error => {
        notification.success({
          message: '預約參觀失敗',
          description:
            '您的資料沒有送出成功，請在稍後嘗試。',
          placement: 'bottomRight',
        });
        console.error('Error!', error.message)
      })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const [form] = Form.useForm();
  // const onGenderChange = (value) => {
  //   switch (value) {
  //     case 'male':
  //       form.setFieldsValue({ note: 'Hi, man!' });
  //       return;
  //     case 'female':
  //       form.setFieldsValue({ note: 'Hi, lady!' });
  //       return;
  //     case 'other':
  //       form.setFieldsValue({ note: 'Hi there!' });
  //   }
  // };

  const NameFields = ({ isDesktop = true }) => (
    <>
      <Form.Item
        // label="lastName"
        name="lastName"
        rules={[{ required: true, message: '請輸入姓氏!' }]}
        style={isDesktop ? { display: 'inline-block', width: 'calc(50% - 5px)', margin: '0 10px 0 0' } : {}}

      >
        <Input
          placeholder="姓氏"
        />
      </Form.Item>
      <Form.Item
        // label="firstName"
        name="firstName"
        rules={[{ required: true, message: '請輸入名字!' }]}
        style={isDesktop ? { display: 'inline-block', width: 'calc(50% - 5px)' } : {}}
      >
        <Input
          placeholder="名字"
        />
      </Form.Item>
    </>
  )

  return (
    <>
      <div className={`${className} ${styles.form}wrapper bg-purple xl:round-tr p-10`}>
        <div className="pb-8">
          <h1 className={`${styles.title} text-blue`}>參觀預約申請</h1>
          <p className="text-left text-gray-10 p-s2 hidden lg:block">請留下你的資料，我們會在三個工作天內盡快與你聯繫，謝謝！</p>
        </div>
        <Form
          name="basic"
          form={form}
          // labelCol={{ span: 12, offset: 12 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: '100%' }}
        >
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item name="據點選擇" rules={[{ required: true, message: '請選擇您的場館!' }]}>
              <Select
                placeholder="請選擇"
                onChange={() => { }}
                allowClear
              >
                {locationList.map((location, idx) => <Select.Option key={idx} value={location.name}>{location.name}</Select.Option>)}
              </Select>
            </Form.Item>
            {/* <button className="bg-blue text-white px-5 py-2 rounded-md">完成</button> */}
          </Form.Item>
          <Form.Item
            // label="firstName"
            name="姓名"
            rules={[{ required: true, message: '請輸入姓名!' }]}
          >
            <Input
              placeholder="姓名"
            />
          </Form.Item>
          <Form.Item
            // label="firstName"
            name="聯絡電話"
            rules={[{ required: true, message: '請輸入電話號碼!' }]}
          >
            <Input
              placeholder="聯絡電話（手機/市話）"
            />
          </Form.Item>

          <Form.Item name="公司類型" rules={[{ required: true, message: '請選擇您的服務單位!' }]}>
            <Select
              placeholder="公司類型請選擇"
              onChange={() => { }}
              allowClear
            >
              <Select.Option value="1-3人團隊">1-3人團隊</Select.Option>
              <Select.Option value="4-10人團隊">4-10人團隊</Select.Option>
              <Select.Option value="10人以上">10人以上</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            // label="firstName"
            name="E-mail"
            rules={[{ required: true, message: '請輸入E-mail!' }]}
          >
            <Input
              placeholder="E-mail"
            />
          </Form.Item>
          <Form.Item
            // label="firstName"
            name="公司名稱"
            rules={[{ required: true, message: '請輸入公司名稱!' }]}
          >
            <Input
              placeholder="公司名稱"
            />
          </Form.Item>


          <Form.Item
            // label="firstName"
            name="需求座位數"
            rules={[{ required: true, message: '請輸入需求座位數!' }]}
          >
            <Input
              placeholder="需求座位數"
            />
          </Form.Item>
          <Form.Item>
            <button type="submit" className={`bg-blue text-white rounded-lg w-full py-2 my-2`}>送出預約</button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = (state => {
  const { isOpenSelectSeatModal, cities, industry, locationList, trial } = state.reducer;
  return {
    isOpenSelectSeatModal,
    locationList,
    trial,
    industry,
    cities
  }
})

const mapStateToDispatch = (dispatch) => {
  return {
    boundFetchLocationList: () => dispatch(fetchLocationList()),
    // boundFetchSpaceStatus: (payload) => dispatch(fetchSpaceStatus(payload)),
    // boundFetchJobIndustry: () => dispatch(fetchJobIndustry()),
    // boundFetchCities: () => dispatch(fetchCities({ country_zip: ZIP.TAIWAN, lang: 'cht' })),
    // openSelectSeatModal: (payload) => dispatch({ type: actionTypes.OPEN_SELECT_SEAT_MODAL, payload }),
  }
}

export default connect(mapStateToProps, mapStateToDispatch)(FillForm);
