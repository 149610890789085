import { LocationMarkerIcon, CheckIcon, SelectorIcon, ChevronDownIcon, CalendarIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import Image from 'next/image'
import styles from './ShowGoogleMapButton.module.css';

const ShowGoogleMapButton = ({ isDisplayMapButton = true, handleClick }) =>
  <button className={`${styles.wrapper} ${isDisplayMapButton ? 'bg-blue opacity-100' : 'bg-blue-c100 opacity-25'}`} onClick={handleClick}>
    <div className="w-5 text-white">
      {/* <Image width="20" height="20" layout="responsive" src={`/space_detail/Icon_Location_Blue.svg`} alt={``} /> */}
      <LocationMarkerIcon />
    </div>
    <p>{isDisplayMapButton ? '顯示' : '不顯示'} Google Map</p>
  </button>


export default ShowGoogleMapButton;
