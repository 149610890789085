import React, { useState, useEffect } from 'react';
import { TimePicker, DatePicker, Form, Input, Select, Option, Button, Checkbox, message } from 'antd';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { actionTypes, fetchLocationList, fetchSpaceStatus, fetchJobIndustry, fetchCities } from '@/lib/actions';
import { DATE_FORMAT, DATETIME_FORMAT, LIVING_AREA, ATTENDEE_TYPE, ZIP, basicColumns } from '@/lib/constants';
import styles from '@/styles/OfficeReservation.module.css'
import Link from 'next/link';
import _ from 'lodash';
import { disabledDateOnlyOneMonth, disabledDatefromNow, disabledTime, disabledDateOnlyOneYear } from '@/lib/utils';
import { DPicker, DTPicker } from '@/components/widgets/Button/DatePicker';
import PureImage from '@/components/widgets/PureImage';
import { range } from 'lodash';

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

const MomerizedDatePicker = React.memo(DPicker)

const FreeTrialForm = ({
  className,
  defaultValues,
  handleFormSubmit = () => { },
  isNeedRenderForm,
  isOpenSelectSeatModal,
  openSelectSeatModal,
  trial,
  cities,
  industry,
  locationList,
  boundFetchLocationList,
  boundFetchSpaceStatus,
  boundFetchJobIndustry,
  boundFetchCities,
}) => {

  const remainder = 30 - (moment().minute() % 30);
  const nearestTime = moment().add(remainder, 'minutes').format(DATETIME_FORMAT)
  const initialValues = {
    ...trial,
    // date: moment(new Date()).format('YYYY-MM-DD'),
    // time: moment(new Date()).format(DATETIME_FORMAT),
    date: moment(),
    time: moment().add(remainder, 'minutes'),
    ...defaultValues
  }

  const [form] = Form.useForm();
  const [_form, setLocalForm] = useState({ checked: false, date: undefined, end_date: undefined, date_time: undefined, end_date_time: undefined, date_day: undefined, end_date_day: undefined });
  const [isDisabled, setIsDisabled] = useState(!defaultValues.where || false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(!trial.selectedSeat || false);
  const [checked, setChecked] = useState(false);
  const [width, height] = useWindowSize()
  const isMobileWindow = width < 768;
  const [timeRange, setTimeRange] = useState(basicColumns);

  console.log('width', width)
  console.log('height', height)
  console.log('isMobileWindow', isMobileWindow)
  console.log(!isMobileWindow)

  useEffect(() => {
    boundFetchLocationList()
    boundFetchJobIndustry()
    boundFetchCities()
  }, []);

  useEffect(() => {
    if (defaultValues.where) setIsDisabled(!defaultValues.where)
    console.log(checked)
    setIsSubmitDisabled(!trial.selectedSeat || !checked)
  }, [isNeedRenderForm, trial.selectedSeat, defaultValues.where, checked]);

  useEffect(() => {
    setLocalForm({
      ..._form,
      end_date_day: _form.date_day,
      date: _form.date_day && _form.date_time ? moment(`${_form.date_day} ${_form.date_time}`).format(`${DATE_FORMAT} ${DATETIME_FORMAT}`) : undefined
    })
  }, [_form.date_day, _form.date_time])

  useEffect(() => {
    setLocalForm({
      ..._form,
      end_date: _form.end_date_day && _form.end_date_time ? moment(`${_form.end_date_day} ${_form.end_date_time}`).format(`${DATE_FORMAT} ${DATETIME_FORMAT}`) : undefined
    })
  }, [_form.end_date_day, _form.end_date_time])

  useEffect(() => {
    if (
      (_form.date && _form.end_date) &&
      moment(_form.date).valueOf() > moment(_form.end_date).valueOf()
    ) {
      alert('起始時間不能大於結束時間。')
    }
  }, [_form.date_time, _form.end_date_time])

  const onFinish = (values) => {
    console.log('Success:', values);
    // 提交給 action
    const data = {
      ...values,
      date: isMobileWindow ? moment(_form.date).format('YYYY-MM-DD') : moment(values.date).format('YYYY-MM-DD'),
      datetime: isMobileWindow ? moment(_form.date).format('HH:mm') : moment(values.time).format('HH:mm'),
      end_date: isMobileWindow ? moment(_form.end_date).format('YYYY-MM-DD') : moment(values.date).format('YYYY-MM-DD'),
      end_time: isMobileWindow ? moment(_form.end_date).format('HH:mm') : moment(values.end_time).format('HH:mm'),
    }
    console.log(data)
    if (isMobileWindow) {
      if (!moment(_form.date).format('YYYY-MM-DD') || !moment(_form.date).format('HH:mm')) {
        // handleFormSubmit(data)
        alert('請選擇免費體驗時間。')
      }
      if (!moment(_form.end_date).format('YYYY-MM-DD') || !moment(_form.end_date).format('HH:mm')) {
        // handleFormSubmit(data)
        alert('請選擇免費體驗結束時間。')
      }
    }
    // console.log(moment(`${_form.date} ${values.time}`))
    // console.log(moment(`${_form.end_date} ${values.end_time}`))
    // const startTime = moment(`${_form.date}`)
    // const endTime = moment(`${_form.end_date}`)
    // console.log('_form', _form)
    // console.log('values', values)
    // console.log('startTime', endTime)
    // console.log('endTime', endTime)
    // console.log('isBefore', startTime.isBefore(endTime))
    // if (startTime.isBefore(endTime)) {
    //   // console.log('submit')
    handleFormSubmit(data)
    // } else {
    //   alert('離場時間早於出場時間，請再次確認時間。')
    // }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (value) => {
    console.log('location', !form.getFieldValue('location'))
    console.log('date', !form.getFieldValue('date'))
    console.log('time', !form.getFieldValue('time'))
    console.log('end_time', !form.getFieldValue('end_time'))
    console.log(!_form.date)
    console.log(!_form.end_date)
    console.log('isMobileWindow', isMobileWindow)
    const isAntdMobilePickerUnavailable = !_form.date_day || !_form.date_time || !_form.end_date_day || !_form.end_date_time
    const isAntdFormPickerUnavailable = !form.getFieldValue('date') || !form.getFieldValue('time') || !form.getFieldValue('end_time')
    console.log('isAntdMobilePickerUnavailable', isAntdMobilePickerUnavailable)
    console.log('isAntdFormPickerUnavailable', isAntdFormPickerUnavailable)
    setIsDisabled(isMobileWindow ? isAntdMobilePickerUnavailable : isAntdFormPickerUnavailable || !form.getFieldValue('location'))
  }

  const changeTime = (value) => {
    console.log(value)
    let startHour, startTime, startHourNumber;
    console.log('_form,date_time', _form.date_time)
    startHourNumber = Number(value[0])
    startHour = value[1] == '00' ? startHourNumber : startHourNumber + 1
    startTime = value[1] == '00' ? ['00', '30'] : ['00', '30']

    setTimeRange([
      range(startHour, 23).map(hour => moment(`${moment().format(DATE_FORMAT)} ${hour}:00`).format('HH')),
      startTime
    ])
  }

  const onPickerSelect = (type, name, cb) => (value) => {
    if (type == 'picker' && name == 'end_date') {
      console.log('value', value)
      // 選擇結束切換
      // changeTime(value)

    }
  }

  const onPickerChange = (type, name, cb) => (value) => {
    // 執行子方法
    cb(value)
    console.log(name)
    console.log(`${value}change pciker`)
    // 如果是選擇開始時間
    if (type == 'picker' && name == 'date') {
      console.log(type, name)
      console.log('value', value)
      changeTime(value)
    }
    // if (type == 'picker' && name == 'end_date') {
    //   console.log(type, name)
    //   console.log('value', value)
    //   setTimeRange([
    //     // [],
    //     range(value[1] == '00' ? value[0] : value[0], 23),
    //     ['00', '30']
    //   ])
    // }
    // 執行父親方法
    onChange(value)
  }

  const onSelectEndTimeChange = (value) => {
    onChange(value)
  }
  // const onClick = () => !isDisabled && boundFetchSpaceStatus({ location_no: form.getFieldValue('location'), zone_id: 2, booking_date: '2021-09-30', booking_time: '08:00' })
  const onClick = () => {
    console.log('date', form.getFieldValue('date'))
    console.log('time', form.getFieldValue('time'))
    if (!isDisabled) {
      boundFetchSpaceStatus({
        location_no: form.getFieldValue('location'),
        zone_id: 2,
        booking_date: moment(form.getFieldValue('date')).format('YYYY-MM-DD'),
        booking_time: moment(form.getFieldValue('time')).format(DATETIME_FORMAT),
        // booking_end_date: moment(form.getFieldValue('date')).format(DATETIME_FORMAT),
        // booking_end_time: moment(form.getFieldValue('date')).format(DATETIME_FORMAT),
      })
      openSelectSeatModal()
      document.body.style.overflow = 'hidden';
    }
  }

  return (
    <>
      {isNeedRenderForm && <div className={`${className} ${styles.form}wrapper bg-purple round-tr p-10`}>
        <Form
          name="basic"
          form={form}
          // labelCol={{ span: 12, offset: 12 }}
          // wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: '100%' }}
        >
          <div className="border-b-2 py-2">
            {isMobileWindow && <Form.Item className="" style={{ marginBottom: 0 }}>
              <DPicker
                name="date"
                showTag
                hourPlaceholder="選擇入場日期"
                minutesPlaceholder="選擇入場時段"
                defaultValue={_form}
                // setDateHourValue={setDateHourValue}
                onFinish={setLocalForm}
                onChange={onPickerChange}
                onSelect={onPickerSelect}
                suffixIcon={
                  <div className="w-5 h-5 relative date-icon" >
                    <PureImage src="/icons/Icon_Date_Blue.svg" className="w-full relative top-[1px]" />
                  </div>
                }
                suffixSecondIcon={
                  <div className="w-5 h-5 relative date-icon" >
                    <PureImage src="/icons/Icon_Time_Blue.svg" className="w-full relative top-[1px]" />
                  </div>
                }
              />
              <DPicker
                name="end_date"
                showTag
                hourPlaceholder="選擇出場日期"
                minutesPlaceholder={!_form.date_day || !_form.date_time ? '請先選擇好入場時間' : '選擇出場時段'}
                // disabledDateOption={true}
                timeRange={timeRange}
                isEnableDatePick={false}
                isDisableTimePick={!_form.date_day || !_form.date_time}
                defaultValue={_form}
                onFinish={setLocalForm}
                onChange={onPickerChange}
                onSelect={onPickerSelect}
                // dateHourValue={dateHourValue}
                suffixIcon={
                  <div className="w-5 h-5 relative date-icon" >
                    <img src="/icons/Icon_Date_Blue.svg" className="w-full relative top-[1px]" />
                  </div>
                }
                suffixSecondIcon={
                  <div className="w-5 h-5 relative date-icon" >
                    <img src="/icons/Icon_Time_Blue.svg" className="w-full relative top-[1px]" />
                  </div>
                }
              />
            </Form.Item>}
            <div className=""></div>
            {!isMobileWindow && <Form.Item className="" style={{ marginBottom: 0 }}>
              <Form.Item
                label="預約日期" name="date" rules={[{ required: true, message: '請選擇您的預約日期!' }]}
              // style={{ display: 'inline-block', width: 'calc(100%)' }}
              >
                <DatePicker
                  disabledDate={disabledDateOnlyOneYear}
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                  onChange={onChange}
                />
              </Form.Item>
              <Form.Item
                label="入場時段" name="time" rules={[{ required: true, message: '請選擇您的預約起始時段!' }]}
              // style={{ display: 'inline-block', width: 'calc(50%)' }}
              >
                <TimePicker
                  disabledHours={disabledTime}
                  format={DATETIME_FORMAT}
                  minuteStep={30}
                  onChange={onChange}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                label="離場時段" name="end_time" rules={[{ required: true, message: '請選擇您的預約結束時段!' }]}
              // style={{ display: 'inline-block', width: 'calc(50%)' }}
              >
                <TimePicker
                  disabledHours={() => {
                    const selectHour = moment(form.getFieldValue('time')).format('H')
                    console.log(selectHour)
                    return [...Array(24).keys()].filter(v => v >= 23 || v < selectHour)
                    // [0, 1, 2, 3, 4, 5, 6, 23]
                  }}
                  disabledMinutes={() => {
                    const selectHour = moment(form.getFieldValue('time')).format('H')
                    const selectHourEndTime = moment(form.getFieldValue('end_time')).format('H')
                    const selectMinutes = moment(form.getFieldValue('time')).format('mm')
                    console.log(selectMinutes)
                    // return selectMinutes == 30 ? selectHourEndTime === selectHour ? [0] : [] : []
                    return selectMinutes == 30 ? [0] : []
                  }}
                  format={DATETIME_FORMAT}
                  minuteStep={30}
                  onChange={onSelectEndTimeChange}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Form.Item>}
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item label="選擇場館" name="location" rules={[{ required: true, message: '請選擇您的場館!' }]}>
                <Select
                  placeholder="請選擇"
                  onChange={onChange}
                  allowClear
                >
                  {locationList.map((location, idx) => <Select.Option key={idx} value={location.location_no}>{location.name}</Select.Option>)}
                </Select>
              </Form.Item>
              {/* <button className="bg-blue text-white px-5 py-2 rounded-md">完成</button> */}
            </Form.Item>
          </div>
          <div className="flex flex-row justify-between py-2">
            <p className="text-blue title-s3">座位號碼：{trial.selectedCode}</p>
            <button type="button" disabled={isDisabled} className={` p-s2 rounded-md ${!isDisabled ? 'bg-blue text-white cursor-pointer' : 'bg-gray-100 text-gray-500 cursor-not-allowed'} px-5`} onClick={onClick}>選擇座位</button>
          </div>
          <div className="hidden lg:block">
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                // label="lastName"
                name="lastName"
                rules={[{ required: true, message: '請輸入姓名!' }]}
                style={{ display: 'inline-block', width: 'calc(50% - 5px)', margin: '0 10px 0 0' }}

              >
                <Input
                  placeholder="姓氏"
                />
              </Form.Item>
              <Form.Item
                // label="firstName"
                name="firstName"
                rules={[{ required: true, message: '請輸入名字!' }]}
                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}
              >
                <Input
                  placeholder="名字"
                />
              </Form.Item>
            </Form.Item>
          </div>
          <div className="block lg:hidden">
            <Form.Item
              // label="lastName"
              name="lastName"
              rules={[{ required: true, message: '請輸入姓氏!' }]}

            >
              <Input
                placeholder="姓氏"
              />
            </Form.Item>
            <Form.Item
              // label="firstName"
              name="firstName"
              rules={[{ required: true, message: '請輸入名字!' }]}
            >
              <Input
                placeholder="名字"
              />
            </Form.Item>
          </div>
          <Form.Item
            // label="firstName"
            name="contactPhone"
            rules={[
              { required: true, message: '請輸入手機號碼!' },
              // {
              //   validator: (rule, value) => {
              //     if (value.length != 10) {
              //       console.log("invalid mobile number");
              //       Promise.reject('invalid mobile number')
              //       // Promise.reject(new Error('invalid mobile number'))
              //     } else {
              //       console.log("this is a mobil validator", value);
              //       Promise.resolve()
              //     }
              //   }
              // },
            ]}
          >
            <Input
              placeholder="聯絡電話（手機）"
            />
          </Form.Item>
          <Form.Item
            // label="firstName"
            name="email"
            rules={[
              { required: true, message: '請輸入電子郵件!' },
            ]}
          >
            <Input
              placeholder="電子郵件"
            />
          </Form.Item>
          <Form.Item
            name="birth" rules={[{ required: true, message: '請選擇您的出生年月!' }]}
          // style={{ display: 'inline-block', width: 'calc(100%)' }}
          >
            <DatePicker
              disabledDate={disabledDatefromNow}
              format={DATE_FORMAT}
              placeholder="出生年月，例如：1995/7/10"
              style={{ width: '100%' }}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item name="gender" rules={[{ required: true, message: '請選擇您的性別!' }]}>
            <Select
              placeholder="性別"
              allowClear
            >
              <Select.Option value="male">男</Select.Option>
              <Select.Option value="female">女</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            // label="firstName"
            name="livingArea"
            rules={[{ required: true, message: '請輸入居住地區!' }]}
          >
            <Select
              placeholder="工作所在區域"
              allowClear
            >
              {cities.map((location, idx) => <Select.Option key={idx} value={location.zip}>{location.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            // label="firstName"
            name="industry"
            rules={[{ required: true, message: '請輸入行業別!' }]}
          >
            <Select
              placeholder="行業別"
              allowClear
            >
              {industry.map((location, idx) => <Select.Option key={idx} value={location.code}>{location.name}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name="agreement"
          >
            <div className="flex justify-center items-center px-3 gap-4">
              <Checkbox onChange={(e) => setChecked(!checked)} />
              <p>已閱讀並同意 <Link href="/terms" passHref><a className="text-blue underline" target="_blank">「使用者條款」</a></Link></p>
              {/* <input type="checkbox" value={_form.checked} checked={_form.checked} />   */}
            </div>
          </Form.Item>
          <Form.Item>
            <button type="submit" className={`bg-blue text-white rounded-lg w-full py-2 my-2 ${!isSubmitDisabled ? 'bg-blue text-white cursor-pointer' : 'bg-gray-100 text-gray-500 cursor-not-allowed'}`} disabled={isSubmitDisabled}>送出</button>
          </Form.Item>
        </Form>
      </div>}
    </>
  )
}

const mapStateToProps = (state => {
  const { isOpenSelectSeatModal, cities, industry, locationList, trial } = state.reducer;
  return {
    isOpenSelectSeatModal,
    locationList,
    trial,
    industry,
    cities
  }
})

const mapStateToDispatch = (dispatch) => {
  return {
    boundFetchLocationList: () => dispatch(fetchLocationList()),
    boundFetchSpaceStatus: (payload) => dispatch(fetchSpaceStatus(payload)),
    boundFetchJobIndustry: () => dispatch(fetchJobIndustry()),
    boundFetchCities: () => dispatch(fetchCities({ country_zip: ZIP.TAIWAN, lang: 'cht' })),
    openSelectSeatModal: (payload) => dispatch({ type: actionTypes.OPEN_SELECT_SEAT_MODAL, payload }),
  }
}

export default connect(mapStateToProps, mapStateToDispatch)(FreeTrialForm);
