import { Search, MenuDropdown, Layout, CtaBtn, BannerCarousel, Dropdown, BlueButton, LeftTriangleOnTop, RightTriangleOnTop, ScrollToTopBtn, HorizontalBarShape, HorizontalBarBgShape } from '@/components/index';


const Questions = ({ items, keyword, onSetKeyword, searchable = false }) =>
  <>
    {searchable && <Search keyword={keyword} onSetKeyword={onSetKeyword} />}
    {items.map(list => (
      <div key={list.name}>
        {/* {(!keyword || keyword && list.name.includes(keyword)) &&
          <h2 className="text-white text-lg md:text-2xl my-5">{list.name}</h2>
        } */}
        {list.items
          .filter(q => q.title.includes(keyword) || q.description.includes(keyword))
          .map((item, idx) => <MenuDropdown key={idx} item={item} />)
        }
      </div>
    ))}
  </>

export default Questions;
