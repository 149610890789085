import {
  Header,
  Footer,
  Meta,
} from '@/components/index';

export default function Layout({ children }) {
  return (
    <>
      <Meta />
      <Header />
      {children}
      <Footer />
    </>
  )
}
