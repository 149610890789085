import { Logo, Container } from '@/components/index';
import Link from 'next/link';
import Image from 'next/image';
// import { FBIcon, IGIcon, LineIcon, YTIcon, XFLogo } from 'public/icons'
import styles from './Footer.module.scss';
const footerData = [
  {
    head: "首頁",
    items: [{
      name: "健身會員",
      link: "/membership"
    }, {
      name: "商業會員",
      link: "/commercial_member"
    }, {
      name: "合作夥伴",
      link: "https://www.facebook.com/xarefit"
    }]
  }, {
    head: "首頁",
    items: [{
      name: "常見問題",
      link: "/faq"
    }, {
      name: "隱私權政策",
      link: "/privacy"
    }, {
      name: "使用者條款",
      link: "/terms"
    }]
  }, {
    head: "首頁",
    items: [{
      name: "關於",
      link: "/about"
    }, {
      name: "加入我們",
      target: '_blank',
      link: "https://www.104.com.tw/company/1a2x6blkn9?jobsource=joblist_r_cust"
    }, {
      name: "聯繫客服",
      link: "https://line.me/R/ti/p/%40qlm6599f"
    }]
  }
  , {
    head: "首頁",
    items: [{
      name: "運動空間",
      link: "/location"
    }]
  }
]



const ListItem = ({ item }) => {
  return (
    <div className="lg:w-1/5 w-full px-4">
      {/* <h2 className="title-font font-medium text-gray-font tracking-widest text-sm mb-3">{item.head}</h2> */}
      <nav className="list-none mb-10">
        <ul className="list-none">
          {item.items.map((item, idx) => (
            <li key={idx} className="m-3 text-center lg:text-left">
              <a className="text-white" href={item.link} target={item.target}>{item.name}</a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

const Footer = () => {

  const FOOTER_DATA = {
    locations: [
      {
        title: '復北慶城',
        address: '台北市松山區復興北路143號12樓'
      },
      // {
      //   title: '忠孝新生館',
      //   address: '台北市大安區忠孝東路9段88號3樓'
      // },
      // {
      //   title: '忠孝新生館',
      //   address: '台北市大安區忠孝東路9段88號3樓'
      // },
      // {
      //   title: '忠孝新生館',
      //   address: '台北市大安區忠孝東路9段88號3樓'
      // },
      // {
      //   title: '忠孝新生館',
      //   address: '台北市大安區忠孝東路9段88號3樓'
      // }
    ],
    items: [{
      title: "我有空間",
      link: "/package",
      // target: '_blank',
    }, {
      title: "常見問題",
      link: "/faq",
      // target: '_blank',
    }, {
      title: "使用者條款",
      link: "/terms",
      // target: '_blank',
    }, {
      title: "隱私條款政策",
      link: "/privacy",
      // target: '_blank',
    }],
    download: {
      title: '下載APP',
      icons: [
        {
          title: 'App Store',
          link: "https://apps.apple.com/mo/app/xarehub-%E4%BA%AB%E8%81%9A%E8%90%BD-%E5%85%B1%E5%89%B5%E7%A9%BA%E9%96%93%E7%84%A1%E9%99%90%E5%8F%AF%E8%83%BD/id1593326984",
          src: "/icons/Logo_AppStore.svg",
          target: '_blank',
          alt: "App Store"
        },
        {
          title: 'Google Play',
          link: "https://play.google.com/store/apps/details?id=com.fitbutler.xarehub&hl=zh_TW&gl=US",
          src: "/icons/Logo_Google_Play.svg",
          target: '_blank',
          alt: "Google Play"
        },
      ]
    },
    contact: {
      title: '聯絡我們',
      icons: [
        {
          link: "",
          src: "/icons/Logo_Youtube.svg",
          target: '_blank',
          alt: "Youtube"
        },
        {
          link: "https://instagram.com/xarehub",
          src: "/icons/Logo_Instgram.svg",
          target: '_blank',
          alt: "Instagram"
        },
        {
          link: "https://lin.ee/5YDKtXZ",
          src: "/icons/Logo_Line.svg",
          target: '_blank',
          alt: "Line"
        },
        {
          link: "https://www.facebook.com/xarehub/",
          src: "/icons/Group 120.svg",
          target: '_blank',
          alt: "Facebook"
        },
      ]
    },
    copyright: "Copyright © 2020 xarehub. 著作權所有，並保留一切權利。"
  }

  const TopSection = ({ entity }) =>
    <div className="flex flex-col gap-2 w-full lg:w-3/12 order-1 lg:order-2">
      <div>
        <p className={`font-normal text-green py-5 p-s1`}>{entity.download.title}</p>
        <div className="flex flex-row gap-1">
          {entity.download.icons.map(({ link, alt, src, target, title }, idx) => (
            <Link key={idx} href={link} >
              <a className="flex flex-row items-center m-1 w-1/2" target={target} rel="noreferrer">
                <div className="w-6 mr-2">
                  <Image width="20" height="20" layout="responsive" src={src} alt={alt} />
                </div>
                <p className="text-white">{title}</p>
              </a>
            </Link>
          ))}
        </div>
      </div>
      <div>
        <Link href="https://linktr.ee/xarefitgroup" passHref><p className={`font-normal text-green py-5 p-s1`}>{FOOTER_DATA.contact.title}</p></Link>
        <div className="grid grid-cols-4 gap-2">
          {FOOTER_DATA.contact.icons.map(({ link, alt, src, target }, idx) => (
            <>
              {link && <Link key={idx} href={link} >
                <a className="m-1" target={target} rel="noreferrer">
                  <div className="w-10">
                    <Image width="20" height="20" layout="responsive" src={src} alt={alt} />
                  </div>
                </a>
              </Link>}
            </>
          ))}
        </div>
      </div>
    </div>

  const BottomSection = ({ entity }) =>
    <div className="w-full lg:w-9/12 order-2 lg:order-1">
      <div className="flex flex-col lg:flex-row gap-5 my-5">
        {entity.items.map((item, idx) =>
          <div key={idx}>
            <a className={`font-normal p-s1 text-green my-0 ${idx !== entity.items.length - 1 ? 'lg:border-r lg:border-green pr-4' : ''}`} href={item.link}>{item.title}</a>
          </div>
        )}
      </div>
      <div className="p-s1">
        xarehub享聚落
      </div>
      <Link href="/location/chingcheng" passHref>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 cursor-pointer">
          {entity.locations.map((location, idx) => <div key={idx} className="">
            <p className="p-s2">{location.title}</p>
            <p className="p-s2">{location.address}</p>
          </div>)}
        </div>
      </Link>
    </div>

  const CopyRight = ({ entity }) =>
    <div className="justify-start mx-auto py-4 flex flex-wrap flex-col sm:flex-row">
      <p className="text-white p-m-s4">{entity.copyright}</p>
    </div>

  return (
    <>
      <footer className={`${styles.footer} footer text-white md:px-5 xl:px-0`}>
        <div className="container m-auto pl-10 pr-10 lg:p-0">
          <div className=" flex flex-col lg:flex-row">
            <TopSection entity={FOOTER_DATA} />
            <BottomSection entity={FOOTER_DATA} />
          </div>
          {/* <CopyRight entity={FOOTER_DATA} /> */}
        </div>
      </footer>
    </>
  )
}

export default Footer;
