import React, { useState, useEffect } from 'react';
import moment from 'moment';
const CountDown = ({ countdown = 10, gen = () => { } }) => {
  const [count, setCount] = useState(countdown);
  const [waitTime, setWaitTime] = useState(0);
  const [isFirstGen, setIsFirstGen] = useState(true);
  // for first gen
  // gen()
  // for
  useEffect(() => {
    const timer = setInterval(() => {
      console.log(count)
      setCount(count > 0 ? count - 1 : countdown)
      setWaitTime(waitTime + 1)
    }, 1000)
    if (count === 0 || isFirstGen) {
      console.log('gen')
      gen()
      if (isFirstGen) setIsFirstGen(false)
    }
    return () => {
      clearInterval(timer)
    }
  }, [count])
  return (
    <>
      {/* <p className={`text-md`}>{moment.utc(waitTime * 1000).format('HH:mm:ss')}倒數{count}秒，重取QRcode</p> */}
    </>
  )
}

export default CountDown;
