import React, { useRef, useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
// import { IntlProvider, FormattedMessage, FormattedNumber } from 'react-intl'
import styles from './MenuDropdown.module.css'

const MenuDropdown = ({ item }) => {
  const [isCollasped, setCollasp] = useState(true)
  const ref = useRef()
  const handleToggle = (e) => {
    // console.log(ref.current.scrollHeight)
    setCollasp(!isCollasped)
  }


  // $('.jumpLink').on('click', function(event){
  //   event.preventDefault();
  //   // console.log($(this).attr('href'));
  //   getHref($(this).html());
  // });

  // function getHref(href){
  //   var trigger = decodeURI(href).replace('#','');
  //   var limit = $('a[class=cd-faq-trigger]').length;
  //   for (var i = 0; i < limit; i++) {
  //     if($('a[class=cd-faq-trigger]').eq(i).html() == trigger){
  //       console.log(i);
  //       if( $(window).width() < MqM) {
  //         var selectedHref = $('a[class=cd-faq-trigger]').eq(i).closest("ul").attr('id');
  //         console.log("#"+selectedHref);
  //         // console.log($('a[class=cd-faq-trigger]').eq(i).closest("ul").attr('id'));
  //         $('ul[class=cd-faq-group]').removeClass('selected');
  //         $('.cd-faq-items > ul#'+selectedHref).addClass('selected');
  //         $('.cd-faq-items').animate({ 'scrollTop': $('a[class=cd-faq-trigger]').eq(i).get(0).offsetTop}, 200);
  //         // faqsContainer.scrollTop(0).addClass('slide-in').children('ul').removeClass('selected').end().children(selectedHref).addClass('selected');
  //         // console.log(faqsContainer.scrollTop(0).addClass('slide-in').children('ul').removeClass('selected').end().children(selectedHref));
  //         // closeFaqsContainer.addClass('move-left');
  //         // $('body').addClass('cd-overlay');
  //       } else {
  //             $('body,html').animate({ 'scrollTop': $('a[class=cd-faq-trigger]').eq(i).get(0).offsetTop+100}, 200);
  //         $('div[class=cd-faq-content]').eq(i).css('display','block');
  //       }
  //       // $('body,html').animate({ 'scrollTop': $('a[class=cd-faq-trigger]').eq(i).get(0).offsetTop+100}, 200);
  //       // $('div[class=cd-faq-content]').eq(i).css('display','block');
  //     }
  //   }
  // }


  const scorll = () => {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: 1000
    });
  }

  return (
    <div className={styles.dropdown}>
      {/* <FormattedMessage
        id="myMessage"
        defaultMessage="Today is {ts, date, ::yyyyMMdd}"
        values={{ ts: Date.now() }}
      /> */}
      <div className={`rounded ${styles.dropdownTitle} bg-blue text-white text-md md:text-lg flex flex-row justify-between items-center`} onClick={handleToggle}>
        <span className="w-10/12" dangerouslySetInnerHTML={{ __html: item.title }}></span>
        <div className="w-1/12">
          <ChevronDownIcon className={`h-6 w-6 ${!isCollasped ? 'hidden' : 'block'} text-blue`} />
          <ChevronUpIcon className={`h-6 w-6 ${isCollasped ? 'hidden' : 'block'} text-blue`} />
        </div>
      </div>
      <div className={`relative overflow-hidden transition-all max-h-0 duration-700`} style={{ maxHeight: isCollasped ? '' : ref.current.scrollHeight }}>
        <div
          ref={ref}
          className={`${styles.dropdownContent} p-5 md:p-10 text-white text-md md:text-lg cd-faq-content text-left`}
          // className={styles.content}
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </div>
      {/* <li class="relative border-b border-gray-200">



        <div class={`relative overflow-hidden transition-all max-h-0 duration-700 ${isCollasped ? '' : 'max-h-100'}`}>
          <div class="p-6">
            <p>reCAPTCHA v2 is not going away! We will continue to fully support and improve security and usability for v2.</p>
            <p>reCAPTCHA v3 is intended for power users, site owners that want more data about their traffic, and for use cases in which it is not appropriate to show a challenge to the user.</p>
            <p>For example, a registration page might still use reCAPTCHA v2 for a higher-friction challenge, whereas more common actions like sign-in, searches, comments, or voting might use reCAPTCHA v3. To see more details, see the reCAPTCHA v3 developer guide.</p>
          </div>
        </div>

      </li> */}
    </div>
  )
}

export default MenuDropdown;
