import Link from 'next/link'
import Image from 'next/image'
import styles from './Logo.module.css'

const Logo = ({
  location = "/",
  cover = false,
  isSticky,
  width = "235",
  height = "47",
  src = "/icons/Logo.svg",
  alt = "xarehub logo",
}) => {
  return (
    <Link href={location}>
      <a className="flex-shrink-0" >
        {isSticky ?
          <div className={`${cover ? 'w-56' : ''} ${styles.logo} w-40 lg:w-[235px]`}>
            <Image width={width} height={height} layout="responsive" src={src} alt={alt} />
          </div> :
          <>
            <div className={`${cover ? 'w-56' : ''} ${styles.logo} w-40 lg:w-[235px] block lg:hidden`}>
              <Image width={width} height={height} layout="responsive" src={src} alt={alt} />
            </div>
            <div className={`${cover ? 'w-56' : ''} ${styles.logo} w-40 lg:w-[235px] hidden lg:block`}>
              <Image width={width} height={height} layout="responsive" src={src} alt={alt} />
            </div>
          </>
        }
      </a>
    </Link>
  )
}

export default Logo;
